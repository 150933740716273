import { FC } from 'react'

import { AiFillGithub, AiFillLinkedin, AiFillTwitterSquare } from 'react-icons/ai'

enum PreviouslyAt {
  STANFORD = 'Stanford',
  BROWN = 'Brown',
  LINKEDIN = 'Linkedin',
  TWO_SIGMA = 'Two Sigma',
  STITCH_FIX = 'Stitch Fix',
  NEXT_DOOR = 'Nextdoor',
  CLOUDERA = 'Cloudera',
  DOCKER = 'Docker',
  BERKELEY = 'Berkeley',
  APPLE = 'Apple',
  AMAZON = 'Amazon',
  META = 'Meta',
}

interface TeamMemberProps {
  name: string
  title: string
  linkedin: string
  github: string
  twitter: string
  img: string
  previouslyAt: PreviouslyAt[]
}
// Not sure if we can do **kwargs
const TeamMember: FC<TeamMemberProps> = (props) => {
  return (
    <div className='min-w-max w-96 h-96 hover:shadow-2xl shadow-lg bg-white flex flex-col items-center bg-opacity-80'>
      <img src={props.img} alt={props.name} className='rounded-full h-40 w-40 mb-10 mt-10'></img>
      <h3 className='text-xl font-semibold'>{props.name}</h3>
      <p className='text-lg'>{props.title}</p>
      <div className='flex flex-row gap-2 text-2xl mt-6'>
        {props.github && (
          <a href={props.github} className='hover:scale-175' target='_blank' rel='noreferrer'>
            <AiFillGithub />
          </a>
        )}
        <a href={props.linkedin} className='hover:scale-175' target='_blank' rel='noreferrer'>
          <AiFillLinkedin />
        </a>
        <a href={props.twitter} className='hover:scale-175' target='_blank' rel='noreferrer'>
          <AiFillTwitterSquare />
        </a>
      </div>
    </div>
  )
}
const About: FC = () => {
  const team: TeamMemberProps[] = [
    {
      name: 'Stefan Krawczyk',
      title: 'Co-founder & CEO',
      linkedin: 'https://www.linkedin.com/in/skrawczyk/',
      github: 'https://github.com/skrawcz',
      twitter: 'https://twitter.com/stefkrawczyk',
      img: 'team/stefan.jpg',
      previouslyAt: [
        PreviouslyAt.STANFORD,
        PreviouslyAt.LINKEDIN,
        PreviouslyAt.NEXT_DOOR,
        PreviouslyAt.STITCH_FIX,
      ],
    },
    {
      name: 'Elijah ben Izzy',
      title: 'Co-founder & CTO',
      linkedin: 'https://www.linkedin.com/in/elijahbenizzy/',
      github: 'https://github.com/elijahbenizzy',
      twitter: 'https://twitter.com/elijahbenizzy',
      img: 'team/elijah.png',
      previouslyAt: [PreviouslyAt.BROWN, PreviouslyAt.TWO_SIGMA, PreviouslyAt.STITCH_FIX],
    },
    {
      name: 'Shreya Shankar',
      title: 'Product & UX Advisor',
      linkedin: 'https://www.linkedin.com/in/shrshnk',
      github: 'https://github.com/shreyashankar',
      twitter: 'https://twitter.com/sh_reya',
      img: 'team/shreya.jpeg',
      previouslyAt: [],
    },
    {
      name: 'Dr. Lamia Youseff',
      title: 'Strategy Advisor',
      linkedin: 'https://www.linkedin.com/in/lyouseff',
      github: '',
      twitter: 'https://twitter.com/lyouseff',
      img: 'team/lamia.jpeg',
      previouslyAt: [PreviouslyAt.AMAZON, PreviouslyAt.APPLE, PreviouslyAt.META],
    },
    {
      name: 'Chad Metcalf',
      title: 'GTM Advisor',
      linkedin: 'https://www.linkedin.com/in/chadmetcalf',
      github: '',
      twitter: 'https://twitter.com/metcalfc',
      img: 'team/chad.jpg',
      previouslyAt: [PreviouslyAt.CLOUDERA, PreviouslyAt.DOCKER],
    },
    {
      name: 'Shani Shoham',
      title: 'GTM Advisor',
      linkedin: 'https://www.linkedin.com/in/shanishoham',
      github: '',
      twitter: 'https://twitter.com/shohams',
      img: 'team/shani.jpeg',
      previouslyAt: [PreviouslyAt.BROWN],
    },
  ]
  return (
    <div className='flex flex-col items-center text-center sm:mb-40 mt-20 sm:mt-32 '>
      <div className='w-full sm:px-20 px-10 max-w-3xl text-md sm:text-lg'>
        <h2 className='text-5xl font-semibold text-slate-700 mb-6 py-3'>About Us</h2>
        <p className='text-justify text-slate-700 mb-8'>
          At DAGWorks Inc. our mission is to enable everyone to build Reliable AI. We&apos;re fully
          open source, and provide a unique integrated development &amp; observability experience
          for those building anything in the AI space. This is the first step towards laying the
          foundations for Composable AI Systems; all AI systems need observability and introspection
          to be first class for them to be reliable.
          <br />
          <br />
          How? We&apos;re standardizing how people write python to express data, ML, LLM, &amp;
          agent workflows / pipelines / applications with lightweight frameworks. So that no matter
          the author, it&apos;ll be easy to collaborate, connect, and importantly in one line
          integrate observability and datastore needs. This speeds up time to production and reduces
          TCO because code remains easy to maintain and your data flywheel stays manageable. So you
          can increase the top line &amp; bottom line of your business by delivering on AI that is
          reliable:
          <br />
          <br />
          <b>Burr</b> standardizes how stateful applications are written &amp; executed. Integrate
          full tracing and observability with a single line.
          <br />
          <br />
          <b>Hamilton</b> standardizes the way individuals and teams express data, machine learning,
          &amp; LLM pipelines. Integrate catalog, observability, lineage, and versioning with a
          single line.
        </p>
        <p className='text-slate-700'>
          Note: the name DAGWorks is an homage to the{' '}
          <a
            href='https://en.wikipedia.org/wiki/Directed_acyclic_graph'
            className='text-dwlightblue'
          >
            Directed Acyclic Graph
          </a>
          , a data structure that models flows of computation.
        </p>
      </div>
      <div className='w-full sm:px-20 px-10 max-w-3xl text-md sm:text-lg mb-10'>
        <h2 className='text-5xl font-semibold text-slate-700 mb-10 py-3 mt-16'>The Team</h2>
        <p className='text-slate-700'>
          Stefan and Elijah are not only the creators of{' '}
          <a
            href='https://github.com/dagworks-inc/hamilton'
            target='_blank'
            rel='noreferrer'
            className='text-dwlightblue'
          >
            Hamilton
          </a>
          &nbsp;and&nbsp;
          <a
            href='https://github.com/dagworks-inc/burr'
            target='_blank'
            rel='noreferrer'
            className='text-dwlightblue'
          >
            Burr
          </a>
          . They also have over twenty years collective experience in helping businesses deliver
          reliable data, ML, and AI products. In particular together they built the self-service
          MLOps Stack/ML platform used by over 100 Data Scientists at Stitch Fix that brought in
          over $1B in revenue, while Elijah built systems at TwoSigma that enabled Quants to
          reliably bring in billions for the TwoSigma hedge fund.
        </p>
      </div>
      <div className='flex flex-row flex-wrap justify-center gap-10 sm:gap-20 mx-3'>
        {team.map((member) => {
          return (
            <>
              <TeamMember {...member}></TeamMember>
            </>
          )
        })}
      </div>
      {/* TODO -- add this in when we have permission from investors */}
      {/* <div className='w-full sm:px-20 px-10 max-w-3xl text-md sm:text-lg mb-10'>
        <h2 className='text-5xl font-semibold text-slate-700 mb-10 py-3 mt-16'>Backed By</h2>
        <p className='text-slate-700 text-8xl hover:scale-175'>😉</p>
      </div> */}
    </div>
  )
}

export default About
